<template lang="pug">

</template>

<script>
import ENV from '../../local_env.json'
import { GetToken } from '@/lib/helpers/Token'

export default {
  data () {
    return {
    }
  },

  async created() {
    window.location.href = `${ENV.backformRouter}?third_app_id=7748955b16d6f1a0&third_token=${GetToken()}&errorUrl=${window.location.href}`
  },

  async mounted () {
  },
}
</script>

<style scoped>
  
</style>
